<template>
  <div class="contact-page">
    <div class="container">
      <div class="section-contact">
        <div class="col-title">
          <div class="block-bg">
            <h3>CONTÁCTANOS</h3>
          </div>
        </div>
        <div class="col-image">
          <img src="@/assets/contact-banner.jpg" alt="">
        </div>
      </div>
      <div class="section-info">
        <div class="block-info">
          <h4>Reservaciones</h4>
          <hr>
          <p class="address"  style="font-size: 14px;">
            Informes y Reservas<br/><i class="fa-solid fa-phone-volume"></i> +51 989 277 813 <br/> <i class="fa-solid fa-envelope"></i> <span style="text-transform: lowercase !important;font-family: sans-serif !important;">ventas@hotelequo.com</span>
          </p>
          <p class="address"  style="font-size: 14px;margin-top: 20px;">
            Recepción: 24 horas<br/><i class="fa-solid fa-tty"></i> +01 680 4640 <br/><i class="fa-solid fa-phone-volume"></i> +51 951 298 766
          </p>
        </div>
        <div class="block-form">
          <div class="mb-3">
            <input type="text" v-model="form.fullname" class="form-control" placeholder="NOMBRE COMPLETO">
          </div>
          <div class="mb-3">
            <input type="email" v-model="form.email" class="form-control" placeholder="EMAIL">
          </div>
          <div class="mb-3">
            <textarea v-model="form.message" class="form-control form-textarea" placeholder="COMENTARIO"></textarea>
          </div>
          <div v-if="error.show" class="text-error">{{ error.text }}</div>
          <div class="text-right">
            <button
              class="btn-submit"
              type="button"
              :class="{ 'loading' : loadForm }"
              @click="validForm"
              :disabled="loadForm"
            >
              <img v-if="loadForm" src="@/assets/icon-loader.svg" />
              {{ loadForm ? 'ENVIANDO...' : 'ENVIAR' }}</button>
          </div>
          <div class="modal fade" tabindex="-1" :class="{ 'show': dialogSuccess }" :style="`display: ${dialogSuccess ? 'block': 'none'}`">
            <div class="modal-dialog modal-auth">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="message text-center">
                    <h3>Hola, {{ form.fullname }}</h3>
                    <p class="text-description text-center mb-4 font-300">Gracias por escribirnos, en breve te responderemos.</p>
                    <div class="list-buttons">
                      <button type="button" class="btn-submit" @click="dialogSuccess = false">
                        <span>CERRAR</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="dialogSuccess" class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
export default {
  name: 'ContactPage',
  data: () => ({
    dialogSuccess: false,
    textForm: 'Su Mensaje a sido enviado correctamente',
    loadForm: false,
    form: {
      fullname: '',
      email: '',
      message: ''
    },
    error: {
      text: '',
      show: false,
    },
  }),
  methods: {
    validForm() {
      if (this.loadForm) return false
      this.loadForm = true
      this.error.show = false
      if (this.isEmpty(this.form.fullname)) {
        this.loadForm = false
        this.error.text = 'El campo Nombre es querido.'
        this.error.show = true
        return false
      }
      if (this.isEmpty(this.form.email) || !this.validateEmail(this.form.email)) {
        this.loadForm = false
        this.error.text = 'El campo Email es querido.'
        this.error.show = true
        return false
      }
      if (this.isEmpty(this.form.message)) {
        this.loadForm = false
        this.error.text = 'El campo Comentario es querido.'
        this.error.show = true
        return false
      }
      this.onSubmitContact()
    },
    async onSubmitContact() {
      try {
      const urlBase = 'https://hotelequo.com/nestjs'
        const response = await this.$axios.post(urlBase, this.form);
        console.log('success', response)
        this.dialogSuccess = true;
        this.form.fullname = ''
        this.form.email = ''
        this.form.message = ''
      } catch({ response }) {
        console.log('error', response)
      } finally {
        this.loadForm = false;
      }
    }
  }
}
</script>
